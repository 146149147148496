import { createSlice } from "@reduxjs/toolkit";
import { postHelpAction } from "./communityHelpAction";

const initialState = {
  help: "",
  loading: false,
  status: "",
  error: "",
};

const communityHelpSlice = createSlice({
  name: "communityHelp",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(postHelpAction.fulfilled, (state, action) => {
        state.help = action?.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(postHelpAction.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(postHelpAction.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export default communityHelpSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLatestAdBanner } from "../../axios/axios";

import Notifications from "../../helpers/Notifications";

export const latestAdBanner = createAsyncThunk(
  "adBanner/get",
  async (props, thunkAPI) => {
    try {
      const response = await getLatestAdBanner();

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

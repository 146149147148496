import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCommitteeMasterList,
  getCommitteeMembersList,
} from "../../axios/axios";

import Notifications from "../../helpers/Notifications";

export const committeeMasterList = createAsyncThunk(
  "committee-master-list/get",
  async (props, thunkAPI) => {
    try {
      const response = await getCommitteeMasterList();

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeeMemberList = createAsyncThunk(
  "committee-member-list/get",
  async (props, thunkAPI) => {
    try {
      const response = await getCommitteeMembersList(props);

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

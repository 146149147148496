import { createSlice } from "@reduxjs/toolkit";
import {
  birthdayNotifications,
  cityDropDown,
  cityDropDownList,
  countryDropDown,
  educationsDropDown,
  gotraDropDown,
  stateDropDown,
  stateDropDownList,
  villageDropDown,
} from "./masterAction";

const initialState = {
  country: [],
  state: [],
  city: [],
  village: [],
  gotra: [],
  education: [],
  birthdays: [],
  status: "",
};

const MasterDropDownSlice = createSlice({
  name: "masterDropDown",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(countryDropDown.fulfilled, (state, action) => {
        state.country = action.payload;
      })
      .addCase(countryDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(countryDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(stateDropDown.fulfilled, (state, action) => {
        state.state = action.payload;
      })
      .addCase(stateDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(stateDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(stateDropDownList.fulfilled, (state, action) => {
        state.state = action.payload;
      })
      .addCase(stateDropDownList.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(stateDropDownList.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(cityDropDown.fulfilled, (state, action) => {
        state.city = action.payload;
      })
      .addCase(cityDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(cityDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(cityDropDownList.fulfilled, (state, action) => {
        state.city = action.payload;
      })
      .addCase(cityDropDownList.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(cityDropDownList.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(villageDropDown.fulfilled, (state, action) => {
        state.village = action.payload;
      })
      .addCase(villageDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(villageDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(gotraDropDown.fulfilled, (state, action) => {
        state.gotra = action.payload;
      })
      .addCase(gotraDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(gotraDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(educationsDropDown.fulfilled, (state, action) => {
        state.education = action.payload;
      })
      .addCase(educationsDropDown.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(educationsDropDown.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      })

      .addCase(birthdayNotifications.fulfilled, (state, action) => {
        state.birthdays = action.payload;
      })
      .addCase(birthdayNotifications.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(birthdayNotifications.rejected, (state, action) => {
        state.status = "Failed to fetch data";
      });
  },
});

export default MasterDropDownSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import Request from "../../helpers/request.service";


export const fetchNewsEvent = createAsyncThunk(
    "get/newsEventList", async (props, { rejectWithValue }) => {
        try {
            let url = "/news/";
            if (props?.latest && props?.latest === true) {
                url = url + "?latest=true";
            }
            else if (props?.search && props?.search.length > 0) {
                url = url + `?search=${props.search}`
            }
            const response = await Request.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const fetchSingleNewsEvent = createAsyncThunk(
    "get/newsEventSingle", async (props, { rejectWithValue }) => {
        try {
            const response = await Request.get(`/news/${props.id}/`);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

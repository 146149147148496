import { createSlice } from "@reduxjs/toolkit";
import {
  committeeMasterList,
  committeeMemberList,
} from "./samajCommitteesAction";

const initialState = {
  committeesList: [],
  memberList: [],
  status: "",
  error: "",
};

const committeeMasterListSlice = createSlice({
  name: "committeeMasterList",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeeMasterList.fulfilled, (state, action) => {
        state.committeesList = action?.payload?.data;
        state.error = "";
      })
      .addCase(committeeMasterList.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(committeeMasterList.rejected, (state, action) => {
        state.committeesList = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

const committeeMemberListSlice = createSlice({
  name: "committeeMemberList",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeeMemberList.fulfilled, (state, action) => {
        state.memberList = action?.payload?.data;
        state.error = "";
      })
      .addCase(committeeMemberList.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(committeeMemberList.rejected, (state, action) => {
        state.memberList = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export const committeeMasterListReducer = committeeMasterListSlice.reducer;
export const committeeMemberListReducer = committeeMemberListSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { aboutUsContent } from "./aboutAction";

const initialState = {
  content: "",
  status: "",
  error: "",
};

const aboutUsContentSlice = createSlice({
  name: "aboutUsContent",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(aboutUsContent.fulfilled, (state, action) => {
        state.content = action?.payload?.data?.content;
        state.error = "";
      })
      .addCase(aboutUsContent.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(aboutUsContent.rejected, (state, action) => {
        state.content = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export default aboutUsContentSlice.reducer;

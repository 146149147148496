import { API_URL } from '../config/const';
import axios from './axiosInstance';
import { downloadBlobData, encodeQuery, serialize, JsonRequestHeaders } from './formHelper';
import { errorHandler, requestInterceptor, successHandler } from './interceptors';
import Auth from './auth.service';

// request interceptor
axios.interceptors.request.use(
  request => requestInterceptor(request)
);
// response interceptor
axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default class Request {

  /**
   * Getter function to get API URL.
   * 
   * @returns {string}
   */
  static get apiUrl() {
    return API_URL;
  }

  /**
   * Function to send GET request.
   * 
   * @param url
   * @param queryParams
   * @returns {Promise<AxiosResponse<any>>}
   */
  static get = function (url, queryParams = '', config = {}) {
    queryParams = !!queryParams ? '?' + encodeQuery(queryParams) : '';
    return axios.get(this.apiUrl + url + queryParams, config);
  };

  /**
   * Function to send POST request. Just pass a JavaScript object to this function,
   * it will automatically convert it into FormData object.
   * 
   * @param url
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  static post = function (url, payload, config = {}) {
    const body = serialize(payload);
    const baseUrl = this.apiUrl;
    return axios.post(baseUrl + url, body, config);
  };

  /**
   * Function to send JSON data via POST request.
   * 
   * @param url
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  static postJson = (url, payload) => {
    const baseUrl = this.apiUrl;

    return axios.post(baseUrl + url, payload, { headers: JsonRequestHeaders });
  };

  /**
   * Function to send PUT request. Just pass a JavaScript object to this function,
   * it will automatically convert it into FormData object.
   * 
   * @param url
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  static put = function (url, payload) {
    const body = serialize(payload);
    return axios.put(this.apiUrl + url, body);
  };

  /**
   * Function to send JSON data via PUT request.
   * 
   * @param url
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  static putJson = (url, payload) => {
    return axios.put(this.apiUrl + url, payload, { headers: JsonRequestHeaders });
  };

  /**
   * Function to send DELETE request.
   * 
   * @param url
   * @returns {Promise<AxiosResponse<any>>}
   */
  static delete = function (url) {
    return axios.delete(this.apiUrl + url);
  };

  /**
   * Helper function to convert array/objects into FormData object.
   * 
   * @param {object}payload
   * @returns {FormData}
   */
  static getFormData = function (payload) {
    return serialize(payload);
  };

  /**
   * Function to download a file via GET request.
   * 
   * @param url
   * @param fileName
   * @param queryParams
   * @returns {Promise<AxiosResponse<any>>}
   */
  static downloadFileViaGet = (url, fileName, queryParams = '') => {
    queryParams = !!queryParams ? '?' + encodeQuery(queryParams) : '';
    return axios.get(this.apiUrl + url + queryParams, { responseType: 'blob' })
      .then((response) => {
        downloadBlobData(fileName, response.data);
      })
      .catch((error) => error);
  };

  /**
   * Function to download a file via POST request.
   * 
   * @param url
   * @param fileName
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static downloadFileViaPost = (url, fileName, data) => {
    const token = Auth.token;
    data = `${data}&token=${token}&requestFrom=new-pc-admin`;
    return axios.post(this.apiUrl + url, data, { responseType: 'blob' })
      .then((response) => {
        downloadBlobData(fileName, response.data);
      })
      .catch((error) => error);
  };
}

import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light'
};

class Notifications {
  static success(message) {
    toast.success(message, toastConfig);
  }

  static warn(message) {
    toast.warn(message, toastConfig);
  }

  static error(message) {
    toast.error(message, toastConfig);
  }

  static info(message) {
    toast.info(message, toastConfig);
  }
}

export default Notifications;

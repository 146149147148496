import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { history } from "./helpers/history";
import HistoryRouter from "./helpers/HistoryRouter";
import { persistor, store } from "./store/store";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <HistoryRouter history={history}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </HistoryRouter>
  // </React.StrictMode>
);

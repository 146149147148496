// export const API_URL = "http://127.0.0.1:8000/api";

export const API_URL = "https://api-beta.raideshsamaj.com/api";

export const HOME_URL = "/app/home";

export const LOGIN_URL = "/user/login";

export const AUTH_TOKEN_NAME = "raidesh_token";

export const USER_OBJECT_NAME = "current_user";

export const AUTH_ENABLED = true;

export const API_MOCKING_ENABLED = false;

import { createSlice } from "@reduxjs/toolkit";

import { choghadiyaAction } from "./choghadiyaAction";


const initialState = {
    currentChoghadiya: {},
    loading: false,
    isDay: null
}

const choghadiyaSlice = createSlice({
    name: "currentChoghadiya",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(choghadiyaAction.fulfilled, (state, action) => {
                state.currentChoghadiya = action.payload.data.success_message;
                state.isDay = action.payload.data.is_day;
                state.loading = false;
            })
            .addCase(choghadiyaAction.pending, (state, action) => {
                state.currentChoghadiya = {};
                state.isDay = null;
                state.loading = true;
            })
            .addCase(choghadiyaAction.rejected, (state, action) => {
                state.currentChoghadiya = {};
                state.isDay = null;
                state.loading = false;
            })
    }
})

export default choghadiyaSlice.reducer;

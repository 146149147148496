import { createSlice } from "@reduxjs/toolkit";
import { listTemples, detailTemple } from "./samajDarshanAction";

const initialState = {
  temples: [],
  templeDetail: "",
  status: "",
  error: "",
};

const listTemplesSlice = createSlice({
  name: "templesList",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(listTemples.fulfilled, (state, action) => {
        state.temples = action?.payload?.data;
        state.error = "";
      })
      .addCase(listTemples.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(listTemples.rejected, (state, action) => {
        state.temples = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

const detailTempleSlice = createSlice({
  name: "templeDetail",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(detailTemple.fulfilled, (state, action) => {
        state.templeDetail = action?.payload?.data;
        state.error = "";
      })
      .addCase(detailTemple.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(detailTemple.rejected, (state, action) => {
        state.templeDetail = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export const templesListReducer = listTemplesSlice.reducer;
export const templeDetailReducer = detailTempleSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";

import { postFeedBack } from "../../axios/axios";
import Notifications from "../../helpers/Notifications";

export const feedback = createAsyncThunk(
  "master/postFeedBack",
  async (data, thunkAPI) => {
    try {
      const response = await postFeedBack(data);
      Notifications.success("Successfully submitted.");

      return response.data.data;
    } catch (error) {
      return Notifications.error("Something went wrong.");
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";

import Auth from "../../helpers/auth.service";
import { history } from "../../helpers/history";
import Request from "../../helpers/request.service";
import Notifications from "../../helpers/Notifications";

export const sendOTP = createAsyncThunk(
  "auth/otp",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/otp/${props}/`);
      return response;
    } catch (error) {
      Notifications.error("Server Error Occurred");
      return rejectWithValue(error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.post(`/otp/${props.memberId}/`, {
        otp: `${props.inputOtp}`,
      });
      if (response.status === 200) {
        if (response?.data?.data?.token) {
          Auth.user = {
            name: response?.data?.data?.full_name,
            member_id: response?.data?.data?.member_id,
          };
          Auth.token = response?.data?.data?.token?.access;
          history.push("/app/home");
          return response;
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk("/auth/logout", async () => {
  try {
    Auth.logout();
    return true;
  } catch (error) {
    return false;
  }
});

import { createSlice } from "@reduxjs/toolkit";
import {
  getMemberAchievementsList,
  getMembersAchievementsDetail,
} from "./memberAchievementAction";

const initialState = {
  membersAchievements: [],
  membersAchievementsDetail: {},
  loading: false,
  status: "",
  error: "",
};

const memberAchievementsSlice = createSlice({
  name: "memberAchievements",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getMemberAchievementsList.fulfilled, (state, action) => {
        state.membersAchievements = action?.payload?.success_message;
        state.loading = false;
        state.error = "";
      })
      .addCase(getMemberAchievementsList.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(getMemberAchievementsList.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.error_message;
      })

      .addCase(getMembersAchievementsDetail.fulfilled, (state, action) => {
        state.membersAchievementsDetail = action?.payload?.success_message;
        state.loading = false;
        state.error = "";
      })
      .addCase(getMembersAchievementsDetail.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(getMembersAchievementsDetail.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.error_message;
      });
  },
});

export default memberAchievementsSlice.reducer;

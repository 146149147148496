import { createSlice } from "@reduxjs/toolkit";
import { latestAdBanner } from "./adBannerAction";

const initialState = {
  banner: [],
  status: "",
  error: "",
};

const latestAdBannerSlice = createSlice({
  name: "latestAdBanner",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(latestAdBanner.fulfilled, (state, action) => {
        state.banner = action?.payload?.data;
        state.error = "";
      })
      .addCase(latestAdBanner.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(latestAdBanner.rejected, (state, action) => {
        state.banner = [];
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export default latestAdBannerSlice.reducer;

import React, { Suspense } from "react";

import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { HOME_URL } from "./config/const";
import FallbackUI from "./helpers/SuspenseFallback";
// import './helpers/axiosMock';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const ProtectedRoutes = React.lazy(() => import("./views/private"));
const PublicRoutes = React.lazy(() => import("./views/public"));

function App() {
  return (
    <>
      <Suspense fallback={<FallbackUI />}>
        <Routes>
          <Route path="/user/*" element={<PublicRoutes />} />
          <Route path="/app/*" element={<ProtectedRoutes />} />
          <Route path="*" element={<Navigate to={HOME_URL} replace />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
}

export default App;
